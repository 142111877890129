.name {
  font-size: clamp(1.4em, 2.1vw, 2.1em);
  font-weight: 800;
  text-transform: lowercase;

}
/*we use this little trick to convert all caps (in the database)
  to lowercase, then capitalize - only works on one line situations*/
.name:first-line {
  text-transform: capitalize;
}
