.msg-input {
  /*background-color: #000036;*/
  bottom:0;
  align-items: center;
  justify-content:space-between;
  display:flex;
  padding-left:10px;
  padding-right:10px;
  border-top:solid;
  border-width:1px;
  border-color:lightgray;
}

.msg-input input {
  background-color: white;
  border:none;
  box-shadow:none;
  font-size:1.25rem;
  height:35px;
  border-radius:5px;
  width:75%;
  margin-right:20px;
}

button {
    height:35px;
    background-color:#757575;
}

