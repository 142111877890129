.outerbox {
    display: flex;
    /*align-items: center;*/
    justify-content: center;
    height: 100%;
    width: 100%;

  }
  
  .innerbox {
    width: 50px;
    /*height: 200px;*/
    color: rgb(211, 211, 211);
    text-align: center;
    font-size: 0.8em;
    margin-top:2em;
  }
  .custom-loader {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 8px solid #0000;
    border-right-color: #8b8b8b;
    position: relative;
    animation: s4 1s infinite linear;
  }
  .custom-loader:before,
  .custom-loader:after {
    content: "";
    position: absolute;
    inset:-8px;
    border-radius: 50%;
    border:inherit;
    animation:inherit;
    animation-duration: 2s;
  }
  .custom-loader:after {
    animation-duration: 4s;
  }
  
  @keyframes s4 {
    100% {transform: rotate(1turn)}
  }
  