.outerbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #000036;
}

.innerbox {
  width: 300px;
  height: 100px;
  color: rgb(211, 211, 211);
  text-align: center;
  font-size: 0.8em;
}

.loader {
  height: 4px;
  width: 300px;
  background: linear-gradient(#000036 0 0), linear-gradient(#000036 0 0),
    #e4e4ed;
  background-size: 60% 100%;
  background-repeat: no-repeat;
  animation: p6 3s infinite;
  margin-bottom:2em;
}

@keyframes p6 {
  0% {
    background-position: -150% 0, -150% 0;
  }
  66% {
    background-position: 250% 0, -150% 0;
  }
  100% {
    background-position: 250% 0, 250% 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
