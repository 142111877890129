.main {
  display: flex;
  align-items: center;
  /* margin-top: 15px; */
  /* margin-bottom: 15px; */
}

.client-card {
  height: 90px;
  border-radius: 10px;
  border-width: thin;
  padding: 2px;
  /* display: flex; */
  flex-direction: row;
  color: black;
}
.utility-icons {
  align-self: flex-end;
  position: absolute;
  margin-top: 15px;
  //margin-bottom:15;
  //bottom:0;
}
.info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.name {
  font-size: clamp(1em, 2vw, 2em);
  font-weight: 800;

  /* text-transform: lowercase; */
}
/*we use this little trick to convert all caps (in the database)
to lowercase, then capitalize - only works on one line situations*/
/* .name:first-line {
  text-transform: capitalize;
} */

.phone {
  font-size: clamp(0.95em, 0.95vw, 0.95em);
  /* line-height: clamp(0.4em, 0.2vw, 0.2em); */
}

.email {
  font-size: clamp(1em, 1vw, 1em);
  line-height: clamp(0.3em, 0.1vw, 0.1em);
}

.edit-icon {
  padding: 1px;
}

.client-status {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  color: #000036;
  height: 40px;
  text-align: center;
}

.is-sale {
  background-color: skyblue !important;
}
