.trix-label-input {
  border: none;
  display: inline;
  min-height: 1em;

  white-space: nowrap;
}

.trix-input-large-screen {
    min-height:400px;
}

.trix-input-small-screen {
    min-height:500px;
}

.title {
    font-size:1.5em!important;
    font-weight:800!important;
    padding-left:5px!important;
    padding-right:0px!important;
    padding-top:0px!important;
    padding-bottom:0px!important;
    margin:0!important;

}
