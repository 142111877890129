.list-item {
  position: relative;
  height: 120px;
  background-color: rgb(246, 245, 245);
}

.list-item:before {
  content: "";
  width: 100%;
  position: absolute;
  margin-left: 0;
  right: 0;
  left: auto;
  bottom: 0;
  border-bottom: 1px solid #f7f7f7;
}

.list-item-needs-attention {
  background-color: #ffffff;
}

.list-item-active {
  background-color:#b8c6db;
}

.name-needs-attention {
  font-weight: 800;
}

/*we use this little trick to convert all caps (in the database)
to lowercase, then capitalize - only works on one line situations*/
/* .name {
  text-transform: lowercase;
}

.name:first-line {
  text-transform: capitalize;
} */
