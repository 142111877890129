.ratings-rectangle {
  background-color: lightgray;
  margin-top: 15px;
  height: 25px;
}

.ratings-rectangle-empty {
  position: relative;
  height: 25px;
  background: lightgray;
}

.ratings-rectangle-empty:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 595.28 841.89' style='enable-background:new 0 0 595.28 841.89;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M0,0c0,209.84,0,632.1,0,841.89h220l281-420.94L210,0H0z'/%3E%3C/svg%3E%0A");
  position: absolute;
  width: 15px;
  height: 25px;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.ratings-rectangle-empty:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 595.28 841.89' style='enable-background:new 0 0 595.28 841.89;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M0,0l297.64,420.94L0,841.89h595.28c0-209.84,0-632.1,0-841.89H0z'/%3E%3C/svg%3E");
  position: absolute;
  width: 15px;
  height: 25px;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.ratings-rectangle-filled {
  position: relative;
  height: 25px;
  background: #b8c6db;
}
.ratings-rectangle-filled:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 595.28 841.89' style='enable-background:new 0 0 595.28 841.89;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M0,0c0,209.84,0,632.1,0,841.89h220l281-420.94L210,0H0z'/%3E%3C/svg%3E%0A");

  position: absolute;
  width: 15px;
  height: 25px;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.ratings-rectangle-filled:after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 595.28 841.89' style='enable-background:new 0 0 595.28 841.89;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M0,0l297.64,420.94L0,841.89h595.28c0-209.84,0-632.1,0-841.89H0z'/%3E%3C/svg%3E");
  position: absolute;
  width: 15px;
  height: 25px;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.status-label {
  font-weight: 800;
  background-color: lightslategray;
  display: inline-block;
  color: white;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  margin-left: 5px;
  margin-top: 10px;
}

.rating-label {
  color: lightslategray;
  padding: 0px;
  border-radius: 0px;
  font-weight: 800;
  text-transform: uppercase;
  margin: 7px 0px 0px 0px;
  border: solid;
  border-width: 1px 0px 1px 0px;
  text-align: center;
  margin-left: 0px !important;
}
