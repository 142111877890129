.statuscard h1 {
  font-size: 1em;
}

.statuscard {
  padding: 4%;
  margin: 4%;
  text-align: center;
  box-shadow: none;
  border:solid;
  border-width:1px;
  border-radius:15px;
  border-color:lightgray;
}
