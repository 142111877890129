.app-body {
    height:calc(100vh);
    width:calc(100vw);
    overflow:hidden;
    margin:0;
    padding:0;

}

/* body,html {
    margin: 0;
    padding: 0;
}
* {
    box-sizing: border-box;
} */